<template>
  <div v-if="isNeedDrawer">
    <SDialog
      v-expose="exposeFormOnGiftCardList()"
      :visible="visible"
      :type="`W720`"
      :show-close="true"
      append-to-body
      @close-from-icon="handleClose"
      @close-from-mask="handleClose"
    >
      <template #title>{{ language?.SHEIN_KEY_PC_33271 }}</template>
      <div class="bsg-drawer-content">
        <GiftForm
          ref="giftFormRef"
          :isShowSwitch="isShowSwitch"
          :isShowStepText="isShowStepText"
          :language="language"
          :isDisabled="isDisabled"
          :giftCardList="giftCardList"
          :isNeedRecordsHistory="isNeedRecordsHistory"
          :sence="sence"
          :errorTip="errorTip"
          :isFormInline="isFormInline"
          :visible="visible"
          :linkBtnTxt="linkBtnTxt"
          @bind-card-cb="bindCardCb"
        />
      </div>
    </SDialog>
  </div>
  <div v-else>
    <GiftForm
      ref="giftFormRef"
      v-expose="exposeFormOnGiftCardList()"
      :isShowSwitch="isShowSwitch"
      :isShowStepText="isShowStepText"
      :language="language"
      :isDisabled="isDisabled"
      :giftCardList="giftCardList"
      :isNeedRecordsHistory="isNeedRecordsHistory"
      :sence="sence"
      :errorTip="errorTip"
      :isFormInline="isFormInline"
      :linkBtnTxt="linkBtnTxt"
      @bind-card-cb="bindCardCb"
    />
  </div>
</template>

<script name="BBindGiftCard" setup lang="ts">
import GiftForm from './components/gift-form.vue'
import { useAnalysis } from '../../common/analysisSource'
import { provide, onMounted, ref, withDefaults } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog/laptop'
import useApis from './hooks/useApis'

interface GiftFormInstance {
  checkGiftCard: (obj) => void;
}
interface propsType {
  sence: 'person' | 'checkout'
  isShowSwitch?: boolean
  isShowStepText?: boolean
  linkBtnTxt?: string
  isNeedDrawer?: boolean
  isDisabled?: boolean
  isFormInline?: boolean
  isNeedRecordsHistory?: boolean
  giftCardList?: Array<any> | null
  visible?: boolean
  errorTip?: string
}

const props = withDefaults(defineProps<propsType>(), {
  sence: 'person',
  isShowSwitch: false,
  isShowStepText: true,
  isFormInline: false,
  isDisabled: false,
  isNeedRecordsHistory: true,
  giftCardList: () => [],
  linkBtnTxt: '',
  errorTip: '',
  isNeedDrawer: true,
  visible: false,
})

const giftFormRef = ref<GiftFormInstance | null>(null)

const emit = defineEmits([
  'handle-close',
  'bind-card-cb',
  'handle-bind-card',
])
const analysisInstance = useAnalysis()
provide('analysisInstance', analysisInstance)
const { vExpose } = analysisInstance
// 埋点源: END
const { langsLaptop } = useApis()
const language = ref<any>(null)

onMounted(() => {
  getLangs()
})

const getLangs = () => {
  langsLaptop().then(res => {
    language.value = res
  })
}

const exposeFormOnGiftCardList = () => {
  let obj = {}
  if(props.sence == 'checkout') {
    obj = {
      id: 'expose_input_giftcard_pop.comp_bind-gift-card',
    }
  }
  return obj
}

const bindCardCb = (data) => {
  emit('bind-card-cb', data)
}
const handleClose = () => {
  emit('handle-close')
}
const checkGiftCard = (data) => {
  if (giftFormRef.value) {
    giftFormRef.value.checkGiftCard(data)
  }
}

const resetCardPinState = () => {
  if (giftFormRef.value) {
    giftFormRef.value.resetCardPinState()
  }
}

defineExpose({
  checkGiftCard,
  resetCardPinState,
})
</script>
<style lang="less">
.bsg-drawer-content {
  padding: 8/37.5rem 12/37.5rem;
}
</style>
