<template>
  <div
    class="bsg-form-container"
    :class="{'bsg-inline-form-container': isFormInline}"
  >
    <SForm
      ref="cardForm"
      :model="cardFormData"
      :validate-on-rule-change="false"
    >
      <div class="bsg-form-container__hint">
        <div class="bsg-form-container__hintgroup bsg-form-container__first-hintgroup">
          <label
            v-if="!isFormInline"
            for="cardNo"
            class="bsg-form-container__hintgroup_origin-label"
          >
            {{ language?.SHEIN_KEY_PC_33256 }}
          </label>
          <input
            id="cardNo"
            v-model.trim="cardFormData.cardNo"
            :disabled="isDisabled"
            class="bsg-form-container__hintgroup_origin-input"
            type="text"
            :maxlength="25"
            :autocomplete="isNeedRecordsHistory ? 'off' : 'on'"
            placeholder="0000 0000 0000 0000"
            readonly
            @input="cardNoBlur"
            @focus="handleCardNoFocus"
            @blur="handleCardNoBlur"
          />
          <div
            v-show="recordsListVisible"
            v-expose="recordsExpose()"
            class="bsg-form-container__hintgroup_records-contain"
          >
            <div class="bsg-form-container__hintgroup_records-wrap">
              <Icon
                class="bsg-form-container__hintgroup_records-close"
                name="sui_icon_closed_18px_1"
                size="18px"
              />
              <span class="bsg-form-container__hintgroup_records-up"></span>
              <div class="bsg-form-container__hintgroup_records-title">
                {{ language?.SHEIN_KEY_PC_29559 }}
              </div>
              <div
                v-for="(item, index) in recordsList"
                :key="index"
                class="bsg-form-container__hintgroup_records-item"
                @click="handleRecordsClick(item)"
              >
                {{ item }}
              </div>
            </div>
          </div>

        </div>
        <div class="bsg-form-container__hintgroup bsg-form-container__second-hintgroup bsg-form-container__mt12">
          <label
            v-if="!isFormInline"
            for="cardPin"
            class="bsg-form-container__hintgroup_origin-label"
          >
            {{ language?.SHEIN_KEY_PC_33257 }}
          </label>
          <input
            id="cardPin"
            v-model.trim="cardFormData.cardPin"
            :disabled="isDisabled"
            class="bsg-form-container__hintgroup_origin-input"
            readonly
            type="password"
            placeholder="0000"
            autocomplete="off"
            @input="filterNumeric"
            onfocus="this.removeAttribute('readonly');this.type='password'"
          />
        </div>
        <SButton
          v-if="isFormInline"
          :type="['H36PX']"
          :block="false"
          :loading="false"
          :disabled="!cardFormData.cardNo || !cardFormData.cardPin || isDisabled"
          class="bsg-inline-form-container__btn"
          @click="submit"
        >
          {{ linkBtnTxt || language?.SHEIN_KEY_PC_33272 || 'Link' }}
        </SButton>
      </div>
      <div
        v-if="isFormInline"
        class="bsg-form-container__error-tip"
      >{{ props?.errorTip }}</div>
      <div
        v-if="isShowSwitch"
        v-expose="swtichExpose()"
        class="bsg-form-container__mt12 bsg-form-container__swtich-container"
      >
        <SCheckbox
          v-model="cardFormData.switchVal"
          v-tap="tapSwtichExpose()"
          :disabled="isDisabled"
          class="bsg-form-container__switch"
        />
        <span>{{ language?.SHEIN_KEY_PC_33258 || 'Link this card to my account' }}</span>
        <SPopover
          class="bsg-form-container__switch-popover"
          theme="dark"
          content="Please choose the reason for cancelling the order"
          placemen="top-start"
        >
          <template #reference>
            <Icon
              name="sui_icon_doubt_14px_1"
              size="14px" />
          </template>
        </SPopover>
      </div>
    </SForm>
    <UseageProcess
      v-if="isShowStepText"
      :language="language"
    />
    <SButton
      v-if="!isFormInline"
      :type="['primary']"
      :loading="false"
      :disabled="!cardFormData.cardNo || !cardFormData.cardPin"
      class="bsg-form-container__btn"
      @click="submit"
    >
      {{ linkBtnTxt || language?.SHEIN_KEY_PC_33272 || 'Link' }}
    </SButton>

    <!-- 二次确认弹框 -->
    <SDialog
      v-if="dialogVisible"
      class="bsg-form-container__dialog"
      :visible="dialogVisible"
      append-to-body
      :show-close="false"
      :close-on-click-modal="false"
      @close="dialogVisible = false"
    >
      <template #title>
        {{ language?.SHEIN_KEY_PC_33259 }}
      </template>
      <p class="bsg-form-container__dialog_text">
        {{ language?.SHEIN_KEY_PC_33260 }}
      </p>
      <template #footer>
        <SButton
          :type="['primary']"
          class="bsg-form-container__dialog_ok"
          block
          @click="checkGiftCard"
        >
          {{ language?.SHEIN_KEY_PC_33261 }}
        </SButton>
        <SButton
          block
          class="bsg-form-container__dialog_cancel"
          @click="handleCancel"
        >
          {{ language?.SHEIN_KEY_PC_24336 }}
        </SButton>
      </template>
    </SDialog>
  </div>
</template>

<script name="BBindGiftCard" setup lang="ts">
import { computed, ref, watch, inject } from 'vue'
import type { DS_BindGiftCard } from '../../../types'
import { SForm } from '@shein-aidc/sui-form/laptop'
import { SToast } from '@shein-aidc/sui-toast/laptop'
import { SDialog } from '@shein-aidc/sui-dialog/laptop'
import { SButton } from '@shein-aidc/sui-button/laptop'
import { SPopover } from '@shein-aidc/sui-popover/laptop'
import useApis from '../hooks/useApis'
import { Icon } from '@shein-aidc/icon-vue3'
import UseageProcess from './useage-process.vue'

const analysisInstance = inject('analysisInstance') as DS_BindGiftCard.AnalysisInstance
const {triggerNotice, vTap, vExpose} = analysisInstance
const props = defineProps<{
  linkBtnTxt?: string
  language?: any
  sence?:any
  isShowStepText?: boolean
  errorTip?: string
  isDisabled?: boolean
  isFormInline?: boolean
  isNeedRecordsHistory?: boolean
  giftCardList?: any
  isShowSwitch?: boolean
  visible?: boolean
}>()

const emit = defineEmits([
  'bind-card-cb',
])
const { postBindGiftCardApi } = useApis()
const cardForm = ref(null)
const dialogVisible = ref(false)
const btnLoading = ref(false)
const isRecordsListVisible = ref(false)
const recordsList = ref([])
const cardFormData = ref({
  cardNo: '',
  cardPin: '',
  switchVal: true,
})
const pin = ref({
  pinInfo: '',
  errorTip: '',
})

watch(() => isRecordsListVisible.value, (n) => {
  if (n) {
    filterRecordsList()
  }
}, {
  immediate: true,
})

const recordsListVisible = computed(() => {
  return (isRecordsListVisible.value && recordsList.value?.length > 0 && props.isNeedRecordsHistory) ? true : false
})


watch(() => props.visible, (n) => {
  if (!n) {
    cardFormData.value = {
      cardNo: '',
      cardPin: '',
      switchVal: true,
    }
  } else {
    recordsList.value = JSON.parse(sessionStorage?.getItem('bind-gift-card-records') || '[]') || []
  }
}, {
  immediate: true,
})

const online = computed(() => {
  return typeof navigator !== 'undefined' ? navigator.onLine : true
})

const handleRecordsClick = (item: string) => {
  isRecordsListVisible.value = true
  cardFormData.value.cardNo = item
  cardFormData.value.cardPin = ''
  props?.sence == 'checkout' && triggerNotice && triggerNotice({
    id: 'click_stored_giftcard_list.comp_bind-gift-card',
  })
}

const saveRecordsList = (data) => {
  sessionStorage.setItem('bind-gift-card-records', JSON.stringify(data))
}

const swtichExpose = () => {
  if(props?.sence == 'checkout') {
    return {
      id: 'expose_bind_card_swtich.comp_bind-gift-card',
      data: {
        switch_status: cardFormData.value?.switchVal ? 'on' : 'off',
      },
    }
  }
}

const tapSwtichExpose = () => {
  return {
    id: 'click_bind_card_swtich.comp_bind-gift-card',
    data: {
      switch_status: cardFormData.value?.switchVal ? 'on' : 'off',
    },
  }
}

const handleCardNoFocus = (e) => {
  isRecordsListVisible.value = true
  e.target.removeAttribute('readonly')
}

const handleCardNoBlur = () => {
  setTimeout(() => {
    isRecordsListVisible.value = false
  }, 200)
}

const recordsExpose = () => {
  if(props?.sence == 'checkout') {
    return {
      id: 'expose_stored_giftcard_list.comp_bind-gift-card',
    }
  }
}

const cardNoBlur =() => {
  cardFormData.value.cardNo = cardFormData.value.cardNo
    .replace(/\s/g, '')
    // .replace(/(\w{4})/g, '$1 ')
    .match(/.{1,4}/g)?.join(' ') || ''
}
const filterNumeric = () => {
  cardFormData.value.cardPin = cardFormData.value?.cardPin?.replace(/[^\d]/g, '')
}
const submit = async function(){
  if (!online.value) {
    SToast({ content: props?.language?.SHEIN_KEY_PC_31378 },2000)
    return
  }
  pin.value.errorTip = ''
  const { cardNo = '', cardPin = '', switchVal } = cardFormData.value
    if(recordsList.value?.length >= 10) {
      recordsList.value?.pop()
    }
    // if(cardNo && cardNo.startsWith('sh') && cardNo.length == 18) {
    if(recordsList.value.filter(item => item == cardNo)?.length <= 0) {
      recordsList.value?.unshift(cardNo)
    }
    // }
    saveRecordsList(recordsList.value)
  if((props?.isShowSwitch && !cardFormData.value?.switchVal) || (!props?.isShowSwitch && props?.sence == 'checkout')) {
    const card_no = cardNo.replace(/\s/g, '')
    emit('bind-card-cb', {
      card_no,
      card_pin: cardPin,
      isBindCard: props?.isShowSwitch ? switchVal : false,
    })
    if(props?.sence == 'checkout') {
      triggerNotice && triggerNotice({
        id: 'click_card_confirm.comp_bind-gift-card',
        data: {
          use_type: 'used',
        },
      })
    }
  } else {
    dialogVisible.value = true
    triggerNotice && triggerNotice({
      id: 'expose_giftcard_link_check.comp_bind-gift-card',
    })
    if(props?.sence == 'checkout') {
      triggerNotice && triggerNotice({
        id: 'click_card_confirm.comp_bind-gift-card',
        data: {
          use_type: 'bind',
        },
      })
    }
  }
}

const filterRecordsList = () => {
  if(props?.giftCardList?.length > 0 && recordsList.value?.length > 0) {
    props?.giftCardList.forEach(item => {
      recordsList.value?.forEach((record:string, index) => {
        if(record?.replace(/\s/g, '') == item.card_no) {
          recordsList.value?.splice(index,1)
          saveRecordsList(recordsList.value)
        }
      })
    })
  }
}


const checkGiftCard = async function(data) {
  try {
    const {reskId = null, validate_token = null } = data
    btnLoading.value = true
    const { cardNo = '', cardPin = '', switchVal } = cardFormData.value
    const card_no = cardNo.replace(/\s/g, '')

    let params: DS_BindGiftCard.BindApiParams = {
      card_no: card_no,
      card_pin: cardPin,
      scene: props?.sence,
    }
    reskId && (params.risk_id = reskId)
    validate_token && (params.validate_token = validate_token)
    postBindGiftCardApi('/user-api/gift_card/bind', params).then((res) => {
      btnLoading.value = false
      dialogVisible.value = false
      emit('bind-card-cb', {
        resData: res,
        card_no,
        card_pin: cardPin,
        isBindCard: props?.isShowSwitch ? switchVal : false,
      })
      if(res.code == 0) {
        props?.language?.SHEIN_KEY_PC_33555 && SToast(
          {
            content: props?.language?.SHEIN_KEY_PC_33555,
          },
          2000,
        )
        cardFormData.value = {
          cardNo: '',
          cardPin: '',
          switchVal: true,
        }
      }
    })
  } catch (error) {
    btnLoading.value = false
  }
}

const handleCancel = () => {
  !btnLoading.value && (dialogVisible.value = false)
}

const resetCardPinState = () => {
  cardFormData.value.cardPin = ''
  pin.value.errorTip = ''
}

defineExpose({
  checkGiftCard,
  resetCardPinState,
})
</script>
<style lang="less">
.bsg-form-container .S-Field__endblock{
  flex: 0;
}
.bsg-form-container {
  .bsg-form-container__hintgroup {
    padding:  10px;
  }
  &__hintgroup {
    position: relative;
    border: 1px solid #E5E5E5;
    padding: 12px;
    margin-top: 20px;
    &_origin-label {
      text-align: left;
      display: block;
      font-size: 14px;
    }
    &_records-contain {
      position: absolute;
      width: 220px;
      left: 0;
      top: 74px;
      padding-top: 15px;
      z-index: 10;
    }
    &_records-item {
      margin-top: 12px;
      font-family: "SF UI Text";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &_records-item:hover {
      cursor: pointer;
    }
    &_records-wrap {
      padding: 12px;
      position: relative;
      background-color: #ffffff;
      border: 2px solid #e5e5e5;
      border-radius: 2px;
      z-index: 10;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.16);
    }
    &_records-title {
      color: #666;
      font-family: "SF UI Text";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &_records-up {
      position: absolute;
      width: 14px;
      height: 14px;
      border-style: solid;
      border-color: var(--el-border-color);
      background: #fff;
      left: 15%;
      transform: translate(-50%) rotate(-135deg);
      border-width: 0 2px 2px 0;
      color: #e5e5e5;
      top: -8px;
    }
    &_records-close {
      position: absolute;
      top: 3px;
      right: 3px;
    }
    &_records-close:hover {
      cursor: pointer;
    }
    &_origin-input {
      display: block;
      width: 100%;
      border: none;
      line-height: 1;
      font-size: 14px;
      color: #222;
    }
    input {
      outline: none;
      &::-webkit-input-placeholder {
        color: #bbbbbb;
      }
      &::-moz-placeholder {
        color: #bbbbbb;
      }
      &:-ms-input-placeholder {
        color: #bbbbbb;
      }
    }
  }
  &__mt12 {
    margin-top: 12px;
  }
  .bsg-form-container__btn {
    display: block;
    margin: 20px auto 0;
  }
  &__dialog {
    &_text {
      margin: 12px 0 0;
      color: #000;
      text-align: center;
      font-family: "SF UI Text";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .bsg-form-container__dialog_cancel {
      margin: 12px auto 0;
    }
    .bsg-form-container__dialog_ok {
      margin: 0 auto;
    }
  }
}
.bsg-inline-form-container {
  .bsg-form-container__hintgroup_records-contain {
    top: 28px;
    z-index: 200;
  }
  .bsg-form-container__switch {
    margin-right: 0;
    font-size: 16px;
  }
  .bsg-form-container__error-tip {
    margin-top: 4px;
    color: #BF4123;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .bsg-form-container__switch-popover {
    margin-left: 4px;
  }
  .bsg-form-container__hint {
    display: flex;
    align-items: center;
  }
  .bsg-form-container__hintgroup {
    display: inline-block;
    border: 1px solid #E5E5E5;
    padding: 9px 12px;
    margin-top: 0;
    flex-grow: 1;
    &_origin-input {
      display: block;
      width: 100%;
      border: none;
      line-height: 1;
      font-size: 14px;
      color: #222;
    }
    input {
      outline: none;
      &::-webkit-input-placeholder {
        color: #bbbbbb;
      }
      &::-moz-placeholder {
        color: #bbbbbb;
      }
      &:-ms-input-placeholder {
        color: #bbbbbb;
      }
    }
  }
  .bsg-form-containerp__first-hintgroup {
    width: 242px;
  }
  .bsg-form-container__second-hintgroup {
    width: 58px;
  }
  .bsg-form-container__mt12 {
    margin-left: 4px;
  }
  .bsg-inline-form-container__btn {
    width: 90px;
    height: 36px;
    margin-left: 4px;
    color: #222;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .bsg-form-container__dialog_text {
    margin: 12px 0 0;
    color: #000;
    text-align: center;
    font-family: "SF UI Text";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .bsg-form-container__dialog_cancel {
    margin-top: 12px;
  }
}
</style>
